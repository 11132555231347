import styled from "styled-components";
import { Box } from "../../components";

const Wrapper = styled.div`
  width: 100%;
`;

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode;
  bottomContent: () => React.ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </div>
  );
}
