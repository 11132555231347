import { ReactElement } from "react";
import Failed from "./_images/failed.svg";

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: ReactElement | string;
  onDismiss?: () => void;
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 h-[300px] w-[300px]">
      <div className="h-24 w-24 flex items-center justify-center">
        <span className="text-6xl font-bold">🤔</span>
      </div>
      <div className="flex flex-col items-center justify-center gap-2  text-orange-200">
        <div className="text-balance text-center">{message || "Waiting for confirmation..."}</div>
        <div className="opacity-60 text-sm">Something went wrong</div>
      </div>
    </div>
  );
}
